'use client';

// copied from src/pages/error/ErrorPage.tsx
// TODO: either create a new Error Page component in this project or in UI library as section to and get rid of client side dependencies

import { ErrorPage as ErrorComponent } from '@grimme/components';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

export function ErrorPage() {
  const { i18n, t } = useTranslation();
  const router = useRouter();

  const buttonText = t('myGRIMME_core_home', 'Startseite');
  const paragraph = t(
    'myGRIMME_core_404_message',
    'Die Seite, nach der du suchst, konnte leider nicht gefunden werden.',
  );
  const title = t('myGRIMME_core_404_heading', 'Falsche Abfahrt?');

  const handleClick = () => router.push(`/${i18n.language}`);

  return (
    <ErrorComponent
      confirmButtonText={buttonText}
      paragraph={paragraph}
      title={title}
      onConfirm={handleClick}
    />
  );
}
