'use client';
// copied for app router from src/pages/layout.tsx
// TODO: refactor to be server component

import { useSearchParams } from 'next/navigation';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, MaintenanceDialog } from '~/components';
import { Navbar } from '~/components/navbar';
import { ButterPage, generateButterSlug, GlobalSettings } from '~/utils';
import { useConfiguration } from '~/utils/config';
import { getCookie, setCookie } from '~/utils/cookies';

// import new app router component

export const PublicLayout = (props: PropsWithChildren) => {
  const { maintenanceMode } = useConfiguration();
  const [globalSettings, setGlobalSettings] = React.useState<
    ButterPage<GlobalSettings>
  >({} as ButterPage<GlobalSettings>);
  const [isPopupOpen, handlePopup] = React.useState<boolean>(
    maintenanceMode &&
      !(getCookie('MY-GRIMME-CORE-MAINTENANCE-POPUP') === 'true'),
  );
  const queryParams = useSearchParams();
  const { i18n } = useTranslation();

  const preview = queryParams?.get('preview') ?? null;
  const language = i18n.language;

  //TODO: should be moved to another hook;
  useEffect(() => {
    const fetchGlobalSettings = async () => {
      const url = generateButterSlug(language, 'global-settings-v3', preview);
      const result = await fetch(url.toString());
      const body = await result.json();
      setGlobalSettings(body.data);
    };
    fetchGlobalSettings();
  }, [language, preview]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const socialLinks = globalSettings?.fields?.social_links;
  const topLinks = globalSettings?.fields?.top_links;
  const navigationLinks = globalSettings?.fields?.navigation_menu;
  const navbarData = globalSettings?.fields?.navbar;

  return (
    <>
      <Navbar
        greeting={globalSettings?.fields?.greeting}
        loggedInMenu={globalSettings?.fields?.logged_in_menu}
        loggedOutMenu={globalSettings?.fields?.logged_out_menu}
        more={globalSettings?.fields?.more}
        navbarData={navbarData}
        navigationLinks={navigationLinks}
        socialLinks={socialLinks}
        topLinks={topLinks}
        withoutContactAssistant={false}
      />
      <main>{props.children}</main>
      {/*
        TODO: Item keys need to be updated in the Old Library Footer
        because is giving an key prop error not fixable in the project.
      */}
      <Footer
        footerData={globalSettings.fields?.footer}
        socialLinks={socialLinks}
        topLinks={topLinks}
        navigationLinks={globalSettings.fields?.navigation_menu}
      />
      <MaintenanceDialog
        onConfirm={() => {
          setCookie('MY-GRIMME-CORE-MAINTENANCE-POPUP', 'true');
          handlePopup(!isPopupOpen);
        }}
        open={isPopupOpen}
      />
    </>
  );
};
